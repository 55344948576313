<template>
    <footer class='add-padding'>
        <div>
            <img src="@/assets/enmarket.svg" alt="">
            <p>620 Stiles Avenue,</p>
            <p>Savannah, GA 31415</p>
            <p>(416) 738-2475</p>
            <a href="mailto:info@EnmarketArena.com">info@EnmarketArena.com</a>
            <Social />
        </div>
    </footer>
</template>

<script>
import Social from '@/components/Social';
export default {
    name:'Footer',
    components : {
        Social
    }
}
</script>

<style lang="scss" scoped>    
    footer {
        background-image:url('~@/assets/images/footer.jpg');    
        background-size: cover;
        position: relative;
        padding-top:4.375rem;
        padding-bottom:4.375rem;
        &:before {
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background: linear-gradient(90deg, #202020 31.31%, rgba(54, 65, 51, 0) 100%);  
            content:"";
        }                
        > div {
            position: relative;
            max-width:1300px;
            margin:0 auto;
        } 
    }
    a {
        color:#fff;
        text-decoration: none;
    }
    p {
        color:#fff;
    }
</style>