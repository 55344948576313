<template>
  <div class='sub-nav add-padding'>
    <div class='limit-width'>
        <div class='items'>
            <router-link v-for="item in items" :key="item.hash" :to="{name: item.parentName, params:$route.params, hash:`#${item.hash}`}" :class="[currentPage === item.hash ? 'active-from-scroll' : '']">{{item.name}}</router-link>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    name:'SubNav',
    props: {
        items:Array,
        currentPage: {
            default:'',
            type:String
        }
    },
    watch : {
       $route: function(){
            if(this.$store.state.preview || this.$store.state.sessionDbId === "") {
                return;
            }
            const hash = window.location.hash.substr(1)
            this.items.forEach(element => {
                if(element.hash === hash) {
                    const post = {
                        method:"page",
                        purl:this.$store.state.purl,
                        sessionDbId:this.$store.state.sessionDbId,
                        section:element.id
                    }
                    axios.post(this.$store.state.baseUrl + "EpitchLiteTracking.php", post);  
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .sub-nav {
        background:#E43141;
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;
        position: sticky;
        top:110px;
        z-index: 3;  
        display:none;      
    }
    .limit-width {        
        text-align: right;       
        .items   {
            padding:0;
            display: inline-grid;
            grid-auto-flow: column;
            gap:2rem;
        }
    }
    a {
        text-decoration: none;
        color:rgba(255,255,255, 0.75);
        user-select: none;
        transition: color 0.25s cubic-bezier(1, 0.5, 0.8, 1);
        &.router-link-exact-active, &.active-from-scroll {
            color:#fff;
        }    
    }
    @media (min-width:760px) {
        .sub-nav {
            display:block;
        }
    }
</style>