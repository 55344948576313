<template>
    <div class='gallery'>
        <div class='gallery-holder'>             
            <div class="carousel">
                <div v-for="(image, id) in images" :key="id" class='image'>
                    <transition name="gallery-fade">
                        <img :src="require('@/assets/images/' + image.file)" :alt="image.title" v-show="galleryActiveSlide === id">
                    </transition>
                </div>                                                    
                <div class="arrow-holder next" @click="galleryActiveSlide = 1 + galleryActiveSlide; if(galleryActiveSlide == images.length) galleryActiveSlide = 0">
                    <div class="custom-arrow">
                        <svg width="18" height="40" viewBox="0 0 7 12" fill="none">
                            <path d="M1.625 1.125L6 5.92683L1.625 10.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            
                <div class="arrow-holder prev" @click="galleryActiveSlide = galleryActiveSlide - 1; if(galleryActiveSlide < 0) galleryActiveSlide = images.length - 1">
                    <div class="custom-arrow">
                        <svg width="18" height="40" viewBox="0 0 7 12" fill="none">
                            <path d="M1.625 1.125L6 5.92683L1.625 10.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="ui" v-if="images.length > 1">
                <div class="pagination-holder">
                    <div class="pagination">
                        <div v-for="(image, id) in images" 
                            :key="id" class='image-name' 
                            :class="[id == galleryActiveSlide ? 'active' : '', id == images.length - 1 ? 'last' : '']" 
                            @click="galleryActiveSlide = id" />
                    </div>
                </div>                
            </div>
            <div class="title" v-if="showTitles && images[galleryActiveSlide].title !== ''">
                <p class="body white">{{images[galleryActiveSlide].title}}</p>
            </div>
        </div>
    </div>    
</template>

<script>
//import VueSlickCarousel from 'vue-slick-carousel';
//import 'vue-slick-carousel/dist/vue-slick-carousel.css'

//import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
    name:'Gallery',
    components: {
        
    },
    props:{        
        activeSlide:{
            type:Number,
            default:0,
        },
        showTitles:Boolean,
        images:Array,
    },    
    watch: {
        images() {
            this.galleryActiveSlide = 0;
        },
        activeSlide(newVal) {
            this.galleryActiveSlide = newVal
        }
    },
    methods: {
        onSlideChange(oldSlideIndex, newSlideIndex) {
            this.galleryActiveSlideAfter = newSlideIndex;
            this.$emit('changeSlide', newSlideIndex);
        },
        onSlideChangeAfter(oldSlideIndex, newSlideIndex) {
            this.galleryActiveSlideAfter = newSlideIndex;
            this.$emit('changeSlide', newSlideIndex);
        },
    },
    data() { return {
        galleryActiveSlide:0,
        galleryActiveSlideAfter:0
    } }
}
</script>

<style lang="scss">
.gallery + div {
    position: relative;
    z-index: 1;
    &.tabs-top {
        z-index: 2;
    }
}
</style>
<style lang="scss" scoped>
.gallery-fade-enter-active {
  transition: all 0.25s 0s ease;
  opacity: 0;   
  
  transform:scale(1.1);
}
.gallery-fade-leave-active {
  transition: all 0.5s 0.25s ease;
  opacity: 0;
  z-index: 1;
  position: relative;
  transform:scale(1.1);
}
.title {
    position:absolute;
    min-width: 238px;
    text-align: center;
    padding:10px;
    bottom:100px;
    right:100px;
    z-index: 2;
    background: #202020;
}
.carousel {
    position: relative;
    height:inherit;    
    .image {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }
}
.gallery {
    overflow:hidden;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    font-size: 0;  
    pointer-events: none;    
    &:after {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.86) 0%, rgba(54, 65, 51, 0) 100%);
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      pointer-events: none;
      z-index: 1;
      content:"";
    }
}
.arrow-holder {
    position:absolute;
    bottom:35px;
    cursor: pointer;
    z-index: 2;
    pointer-events: all;
    &.next {
        right:0;
        padding-right:2.25rem;
    }
    &.prev {
        left:0;
        padding-left:2.25rem;
        svg {
            transform: scaleX(-1);
        }
    }
}
@media (min-width:500px) {
    .arrow-holder {
        &.next {
            padding-right:6.25rem;
        }
        &.prev {
            padding-left:6.25rem;
        }
    }
}
.gallery-holder {
    position:relative;
    height:100%;
    font-size: 0;        
}
.image {
    width:100%;
    height:inherit;
    outline:none;    
    img {        
        height:inherit;
        min-height:calc(100vh - 140px);
        width:100%;
        object-fit: cover;
        object-position: center;
        pointer-events: none;
    }
} 
.ui {
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    text-align: center;
    z-index: 2;
    padding-bottom:35px;
}
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;

}
.image-name {
    width: 10px;
    height: 10px;        
    cursor: pointer;
    padding:5px;
    pointer-events: all;
    &:before {
        display: inline-block;
        width: 10px;
        height: 10px;
        background:#fff;
        box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.25);
        border-radius: 100%;
        transition: background-color 0.25s cubic-bezier(1, 0.5, 0.8, 1);
        content:"";
    }
    &.active:before {
        background-color:#E43141;
    }
    &:not(:last-child) {
        margin-right: 18px;
    }
}
</style>